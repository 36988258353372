export const SizeGuideCaro = (function () {
	let my = {};

	function _handleSizeCaro() {
		const sizeGuideItems = document.querySelectorAll('.size-guide__item');
	
		sizeGuideItems.forEach(item => {
			const carousel = item.querySelector('.size-guide__carousel-container');
			const prevButton = item.querySelector('.size-guide__carousel-button--prev');
			const nextButton = item.querySelector('.size-guide__carousel-button--next');
	
			function scrollCarousel(direction) {
				carousel.scrollBy({
					left: direction * carousel.offsetWidth,
					behavior: 'smooth'
				});
			}
	
			prevButton.addEventListener('click', () => scrollCarousel(-1));
			nextButton.addEventListener('click', () => scrollCarousel(1));
	
			carousel.addEventListener('click', (e) => {
				if (e.target.classList.contains('size-guide__carousel-item')) {
					const newSize = e.target.dataset.size;
					updateSizeGuide(item, newSize);
				}
			});
	
			function updateSizeGuide(item, newSize) {
				const mainImage = item.querySelector('.size-guide__main-image');
				mainImage.src = `/dist/images/what_fits/sizeguide_${newSize}_large.png`;
	
				const carouselItems = item.querySelectorAll('.size-guide__carousel-item');
				carouselItems.forEach(carouselItem => {
					carouselItem.classList.toggle('size-guide__carousel-item--active', carouselItem.dataset.size === newSize);
				});
			}
	
			function checkCarouselOverflow() {
				const isOverflowing = carousel.scrollWidth > carousel.clientWidth;
				prevButton.style.display = isOverflowing ? 'block' : 'none';
				nextButton.style.display = isOverflowing ? 'block' : 'none';
			}
	
			checkCarouselOverflow();
			window.addEventListener('resize', checkCarouselOverflow);
		});
	}

	my.init = function () {
		if(document.querySelector('.size-guide')) {
			_handleSizeCaro();
		}
	};

	return my;
})();