export const headerItems = (function(){
	let my = {};
	const desktopBreakpoint = 950;
	let isDesktop = window.innerWidth > desktopBreakpoint;
	const dropdowns = document.querySelectorAll('.header__dropdown');

	function _hamburger() {
		const openMenu = document.querySelector('.header__menu-icon');
		const closeMenu = document.querySelector('.header__close-icon');

		document.querySelector('.header__hamburger').addEventListener('click', function() {
			document.querySelector('.header__mobile-menu').classList.toggle('active');
			closeMenu.classList.toggle('active');
			openMenu.classList.toggle('active');
		});
	}
	function handleDropdowns() {
		if (isDesktop) {
			dropdowns.forEach(dropdown => {
				const toggle = dropdown.querySelector('.header__dropdown-toggle');
				
				toggle.addEventListener('click', function(event) {
					event.stopPropagation();
					
					dropdowns.forEach(otherDropdown => {
						if (otherDropdown !== dropdown) {
							otherDropdown.classList.remove('active');
						}
					});
					
					dropdown.classList.toggle('active');
				});
			});

			document.addEventListener('click', function() {
				dropdowns.forEach(dropdown => {
					dropdown.classList.remove('active');
				});
			});
		} else {
			dropdowns.forEach(dropdown => {
				const toggle = dropdown.querySelector('.header__dropdown-toggle');
				toggle.removeEventListener('click', toggle.clickHandler);
			});
		}
	}

	function _dropdowns() {
		handleDropdowns();

		window.addEventListener('resize', function() {
			const newIsDesktop = window.innerWidth > desktopBreakpoint;
			
			if (newIsDesktop !== isDesktop) {
				isDesktop = newIsDesktop;
				handleDropdowns();
			}
		});
	}

	my.init = function(){
		_hamburger();
		_dropdowns();
	};

	return my;
})();