import { contactSubmit } from './contact.js';
import { facilityCaro, facilityToggle } from './facility.js';
import { headerItems } from './global/header.js';
import { SizeGuideCaro } from './global/size_guide.js';
import { helpToggle } from './help.js';
import { maps } from './map.js';
import { reviewsCaro } from './reviews.js';

if (document.getElementById('contact_form')) {
	contactSubmit();
}

facilityToggle.init();
facilityCaro.init();
SizeGuideCaro.init();
helpToggle.init();
maps.init();
reviewsCaro.init();
headerItems.init();