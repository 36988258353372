import {
	AutomatitCarousel
} from './automatit_carousel.m';

export const facilityCaro = (function () {
	let my = {};

	my.init = function () {
		if (document.getElementById('facility_caro')) {

			if (window.slide_images.length > 1) {
				const options = {
					element: document.getElementById('facility_caro'),
					speed: 600,
					images: window.slide_images,
					intervalTiming: 6000,
					imagesAsBackgrounds: true
				};
				let caro = AutomatitCarousel(options);
				document.getElementById('caro_right_chevron').addEventListener('click', () => {
					caro.slideRight();
				});
				document.getElementById('caro_left_chevron').addEventListener('click', () => {
					caro.slideLeft();
				});
			} else {
				let btns = document.getElementById('facility_caro_chevrons');
				btns.parentNode.removeChild(btns);
				const options = {
					element: document.getElementById('facility_caro'),
					speed: 600,
					images: window.slide_images,
					imagesAsBackgrounds: true,
					intervalTiming: 0,
					useChevrons: false
				};
				AutomatitCarousel(options);
			}
		}
	};

	return my;
})();

export const facilityToggle = (function () {
	let my = {};

	function _toggleCollapse() {
		const h1 = document.querySelector('#facility_info_container h1');
		const infoContainer = document.getElementById('facility_info_container');
		const detailsToCollapse = infoContainer.querySelectorAll('.detail:not(:first-child), .info_column.hours');

		h1.addEventListener('click', () => {
			detailsToCollapse.forEach(detail => {
				detail.classList.toggle('collapsed');
			});
			h1.classList.toggle('active');
		});
	}

	my.init = function () {
		if(document.querySelector('#facility_info_container')) {
			_toggleCollapse();
		}
	};

	return my;
})();