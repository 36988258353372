export const helpToggle = (function () {
	let my = {};

	function _assignListeners() {
		document.querySelectorAll('.faq-question').forEach(title => {
			title.addEventListener('click', _toggleItem);
		});
	}

	function _toggleItem(e) {
		const question = e.currentTarget;
		const answer = question.nextElementSibling;
		const isActive = question.classList.contains('active');

		document.querySelectorAll('.faq-question.active').forEach(q => {
			if (q !== question) {
				q.classList.remove('active');
				q.nextElementSibling.style.maxHeight = '0px';
				q.nextElementSibling.classList.remove('active');
			}
		});

		question.classList.toggle('active', !isActive);
		answer.classList.toggle('active', !isActive);

		if (!isActive) {
			answer.style.maxHeight = answer.scrollHeight + 'px';
		} else {
			answer.style.maxHeight = '0px';
		}
	}

	my.init = function () {
		if (document.getElementById('accord')) {
			_assignListeners();
			const firstQuestion = document.querySelector('.faq-question');
			if (firstQuestion) {
				firstQuestion.click();
			}
		}
	};

	return my;
})();