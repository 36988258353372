/* eslint-disable no-undef */
import {
	getMapsHelper
} from './util/mapsHelper';
export const maps = (function () {
	let my = {},
		multiMap = document.getElementById('locations_map'),
		mapData = document.querySelectorAll('.location_item'),
		initialized = false,
		theMap,
		infoWindow,
		mapHelper;

	function _startMultiMap() {
		mapHelper = getMapsHelper();
		mapHelper.ready()
			.then(() => {
				theMap = mapHelper.createMap({
					element: multiMap,
					locationElementSelector: '.location_item',
					useRichmarker: true,
					markerReducer: el => {
						return {
							lat: el.getAttribute('lat'),
							lng: el.getAttribute('lng'),
							content: `
											<a class="map_pin" href="${el.getAttribute('link')}" target="${el.getAttribute('target')}">
													<svg xmlns="http://www.w3.org/2000/svg" width="38.66" height="55.62" viewBox="0 0 38.66 55.62"><defs><style>.cls-1{fill:#16184F;stroke-miterlimit:10;}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M38.16,19.43c0,10.46-18.83,35.36-18.83,35.36S.5,29.89.5,19.43a18.83,18.83,0,1,1,37.66,0Z"/></g></g></svg>
													<div><span>${el.getAttribute('index')}</span></div>
											</a>
											`
						};
					}
				});
				infoWindow = new google.maps.InfoWindow();
				google.maps.event.addListenerOnce(theMap, 'idle', () => {
					if (mapData.length < 2) {
						theMap.setZoom(15);
					}
					[].forEach.call(mapData, i => {
						const markerData = mapHelper.getMarker(i);
						const city = i.getAttribute('city');
						const address = i.getAttribute('address');
						const reviews = i.getAttribute('reviews');
						const rates_link = i.getAttribute('rates_link');
						const map_link = i.getAttribute('map_link');
						const contact = i.getAttribute('contact');
						const phone = i.getAttribute('phone');
						const dsp_phone = i.getAttribute('dsp_phone');
						markerData.element.addEventListener('mouseenter', () => {
							_showInfoWindow(markerData.marker, city, contact, phone, dsp_phone, address, reviews, rates_link, map_link, i.getAttribute('link'));
						});
						markerData.element.addEventListener('mouseleave', () => {
							i.classList.remove('hover');
						});
					});
				});

				// Listen for the custom event (for contact page)
				document.addEventListener('facilitySelected', function (e) {
					_showFacilityOnMap(e.detail);
				});

				// Add click event listener for "Get Direction" links
				document.querySelectorAll('.get-direction').forEach(link => {
					link.addEventListener('click', (e) => {
						e.preventDefault();
						const facilityId = e.target.closest('.get-direction').getAttribute('data-facility-id');
						const facilityElement = document.querySelector(`.location_item[data-facility-id="${facilityId}"]`);
						_showFacilityOnMap(facilityElement);
					});
				});
			});
	}

	function _showInfoWindow(marker, city, contact, phone, dsp_phone, address, reviews, rates_link, map_link) {
		infoWindow.setContent(`
					<div class="map_info">
							<h3>Rent Pro Storage - ${city}</h3>
							<a class="add_link" href="${map_link}" target="_blank">${address}</a>
							<hgroup>
									<div class="review_stars">
											<img src="/dist/images/icons/ic_star.svg" alt="">
											<img src="/dist/images/icons/ic_star.svg" alt="">
											<img src="/dist/images/icons/ic_star.svg" alt="">
											<img src="/dist/images/icons/ic_star.svg" alt="">
											<img src="/dist/images/icons/ic_star.svg" alt="">
											<img src="/dist/images/logos/internal.png" alt="" />
									</div>
									<span class="reviews">(${reviews} Reviews)</span>
							</hgroup>
							${contact === 'false' ? `
							<a class="bot_link" href="${rates_link}">
									<span>View Rates <img src="/dist/images/svg/arrow.svg" alt=""/></span>
							</a>
								` : `
							<a class="bot_link" href="tel:+1${phone}">
									<span>${dsp_phone}</span>
							</a>`}
					</div>
			`);
		infoWindow.open(theMap, marker);
	}

	function _showFacilityOnMap(facilityElement) {
		if (!initialized) {
			_startMultiMap();
			initialized = true;
		}

		const markerData = mapHelper.getMarker(facilityElement);
		const city = facilityElement.getAttribute('city');
		const address = facilityElement.getAttribute('address');
		const reviews = facilityElement.getAttribute('reviews');
		const rates_link = facilityElement.getAttribute('rates_link');
		const map_link = facilityElement.getAttribute('map_link');
		const facility_link = facilityElement.getAttribute('link');
		const contact = facilityElement.getAttribute('contact');
		const phone = facilityElement.getAttribute('phone');
		const dsp_phone = facilityElement.getAttribute('dsp_phone');

		theMap.setCenter(markerData.marker.getPosition());
		theMap.setZoom(15);
		_showInfoWindow(markerData.marker, city, contact, phone, dsp_phone, address, reviews, rates_link, map_link, facility_link);
	}

	function _mapInView() {
		const rect = multiMap.getBoundingClientRect();
		const isInView = (rect.top >= 0 && rect.bottom <= ((window.innerHeight || document.documentElement.clientHeight) + 1000));
		return isInView;
	}

	function _assignListener() {
		if (_mapInView()) {
			_startMultiMap();
			initialized = true;
		} else {
			window.onscroll = function () {
				const rect = multiMap.getBoundingClientRect();
				const isInView = (rect.top >= 0 && rect.bottom <= ((window.innerHeight || document.documentElement.clientHeight) + 1000));
				if (!initialized && isInView) {
					_startMultiMap();
					initialized = true;
				}
			};
		}
	}

	my.init = function () {
		if (multiMap) {
			_assignListener();
		}
	};

	return my;
})();